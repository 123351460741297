<template>
  <div>
    <div >
      <div >
        <div class="logo-container">
          <img
            v-if="customLogoUrl"
            v-bind:src="customLogoUrl"
            alt="Welcome"
            class="logo"
          />
          <img
            v-else
            src="../../public/en_logo.png"
            alt="Welcome"
            class="logo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginHeader',
  props: ["customLogoUrl"]
};
</script>
