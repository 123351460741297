<template>
  <div v-cloak>
    <div >
      <div >
        <div v-show="showForm">
          <h4>Reset Forgotten Password</h4>
          <p>
            Enter your username below.
          </p>
          <p>
            Password Reset instructions will be sent to the email address
            associated with your account within 10 minutes.
          </p>

          <div class="form-group">
            <label class="control-label" for="Username"
              >Username</label
            >
            <div class="controls">
              <input
                class="form-control"
                id="Username"
                v-model.trim="username"
                v-on:keyup.enter="handleSend"
                ref="username"
              />
              <span class="text-danger" v-show="missingUsername"
                >you forgot to enter your username</span
              >
            </div>
          </div>

          <div class="form-group">
            <div class="controls">
              <a
                v-on:click.prevent="handleSend"
                class="btn btn-success send-button"
                >Send Email</a
              >
            </div>
          </div>

          <div v-show="errorMessage" class="text-danger">
            {{ errorMessage }}
          </div>

          <div v-show="processingSend">
            <div style="margin-top:10px">
              <img src="../../public/login.gif" alt="Processing..." />
            </div>
          </div>
        </div>

        <div v-show="showForm">
          <div>
            <a href="#" v-on:click.prevent="handleForgotUsername">
              Forgot Username?
            </a>
          </div>
          <br />
          <div>
            <a
              v-bind:href="
                benefitsAppBaseUrl + '/Account/Reset/InitiatePasswordReset'
              "
            >
              My account administrator initiated a Password Reset Request on my
              behalf
            </a>
          </div>
        </div>

        <div v-show="showConfirmation">
          <div>
            <h4>Check Your Email</h4>
            <p>
              Your Password Reset Request will expire in 24 hours.
            </p>
            <br />
            <br />
            <div class="important-note">
              <p>
                Didn't receive an email?
              </p>
              <p>
                Return to the
                <a class="bold-text" href="#" v-on:click.prevent="handleGoBack"
                  >Forgot Password</a
                >
                page to ensure your username was entered correctly.
              </p>
              <p>
                If the problem persists or there is not an email address
                associated with your account, contact your account administrator
                to request a Password Reset.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "./utils.js";

export default {
  props: [
    "forgotPasswordUrl",
    "brandingSubdomain",
    "forgotPasswordInfo",
    "benefitsAppBaseUrl",
  ],
  data() {
    return {
      showForm: true,
      showConfirmation: false,
      username: this.forgotPasswordInfo.suggestedUsername,
      errorMessage: "",
      processingSend: false,
      sendButtonHasBeenClicked: false,
    };
  },
  mounted() {
    this.$refs.username.focus();
  },
  computed: {
    missingUsername() {
      return this.sendButtonHasBeenClicked && !this.username;
    },
  },
  methods: {
    handleForgotUsername() {
      this.$emit("forgot-username-requested");
    },
    handleSend() {
      this.sendButtonHasBeenClicked = true;

      if (this.missingUsername) {
        return;
      }

      this.errorMessage = "";
      this.processingSend = true;

      utils
        .ajax("post", this.forgotPasswordUrl, {
          username: this.username,
          brandingSubdomain: this.brandingSubdomain,
        })
        .then((response) => {
          this.processingSend = false;

          if (response.data.errorMessage) {
            this.errorMessage = response.data.errorMessage;
          } else {
            this.showForm = false;
            this.showConfirmation = true;
          }
        })
        .catch((err) => {
          this.processingSend = false;
          this.errorMessage =
            "An Unknown error has occurred. Please try again later";
          utils.logError(err);
        });
    },
    handleGoBack() {
      this.showConfirmation = false;
      this.showForm = true;
      this.$nextTick(() => this.$refs.username.focus());
    },
  },
};
</script>

<style scoped lang="scss">
h4 {
  font-weight: bold;
}

.important-note {
  font-style: italic;
}

.bold-text {
  font-weight: bold;
}

.send-button {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
