<template>
  <div v-cloak>
    <iframe id="duo_iframe"></iframe>
  </div>
</template>

<script>
import utils from "./utils.js";

export default {
  props: ["loginUrl", "returnUrl", "duoInfo"],
  data() {
    return {
      errorMessage: ""
    };
  },
  mounted() {
    window.Duo.init({
      host: this.duoInfo.host,
      sig_request: this.duoInfo.signatureRequest,
      submit_callback: formData => {
        const response = formData.querySelector("[name=sig_response]").value;

        utils
          .ajax("post", this.loginUrl, {
            username: this.duoInfo.username,
            password: this.duoInfo.password,
            mfaCode: response,
            returnUrl: this.returnUrl
          })
          .then(response => {
            if (response.data.errorMessage) {
              //TODO: figure out how this should be handled
              this.errorMessage = response.data.errorMessage;
            } else if (response.data.redirectUrl) {
              window.location = response.data.redirectUrl;
            }
          })
          .catch(err => {
            //TODO: figure out how this should be handled
            this.errorMessage =
              "An Unknown error has occurred. Please try again later";
            utils.logError(err);
          });
      }
    });
  }
};
</script>

<style scoped lang="scss">

#duo_iframe {
  width: 100%;
  min-width: 304px;
  max-width: 620px;
  height: 330px;
  border: none;
}
</style>
