import axios from "axios";

const getVerificationToken = function() {
  return document.querySelector("input[name='__RequestVerificationToken']")
    .value;
};

const uniqueCharCount = function(str) {
  return [...str].reduce(
    (acc, curr) => (acc.includes(curr) ? acc : acc + curr),
    ""
  ).length;
};

const scrub = function(str) {
  return str
    .toLowerCase()
    .replace(/$/g, "s")
    .replace(/0/g, "o")
    .replace(/!/g, "i")
    .replace(/3/g, "e")
    .replace(/@/g, "a")
    .replace(/1/g, "i");
};

export default {
  ajax(method, url, data) {
    const headers = { "x-verification-token": getVerificationToken() };

    return axios({ method, url, data, headers });
  },

  logError(error) {
    if (window.console && window.console.log) {
      window.console.log("Error: " + error);
    }
  },

  isValidPassword(pwd) {
    return (
      pwd &&
      pwd.length >= 8 &&
      uniqueCharCount(pwd) >= 4 &&
      scrub(pwd).indexOf("employee") == -1 &&
      scrub(pwd).indexOf("navigator") == -1 &&
      scrub(pwd).indexOf("eenav") == -1
    );
  },
};
