<template>
  <div v-cloak>
    <div >
      <div >
        <form>
          <div v-show="showCredentialsForm">
            <div >
              <div class="form-group" :class="{'has-error': missingUsername}">
                <label class="control-label" for="Username"
                  >Username</label
                >
                <div class="controls">
                  <input
                    class="form-control"
                    id="Username"
                    v-model.trim="username"
                    v-bind:disabled="processingLogin || !showCredentialsForm"
                    autofocus
                  />
                  <div class="error-message" v-show="missingUsername"
                    >Username is required.</div
                  >
                </div>
              </div>
            </div>
            <div >
              <div class="form-group" :class="{'has-error': missingPassword}">
                <label class="control-label" for="Password"
                  >Password</label
                >
                <div class="controls">
                  <input
                    class="form-control"
                    id="Password"
                    type="password"
                    v-model.trim="password"
                    v-bind:disabled="processingLogin || !showCredentialsForm"
                    autocomplete="off"
                  />
                  <div class="error-message" v-show="missingPassword"
                    >Password is required.</div
                  >
                </div>
              </div>
            </div>
          </div>

          <div v-show="showMfaCodeForm">
            <div >
              <div class="alert alert-info">
                <span v-if="requiresTotpCode">
                  Please open your Authentication application that you
                  registered with your Employee Navigator account and enter the
                  code in the textbox below and click the Login button.
                </span>

                <span v-if="requiresEmailCode">
                  An email containing a login code has been sent to your
                  registered email address. Please enter the code in the textbox
                  below and click the Login button.
                </span>
              </div>
            </div>

            <div >
              <div class="form-group" :class="{'has-error': missingMfaCode}">
                <label class="control-label" for="MfaCode"
                  >Code</label
                >
                <div class="controls">
                  <input
                    class="form-control"
                    id="MfaCode"
                    v-model.trim="mfaCode"
                    ref="mfaCodeInput"
                    autocomplete="off"
                  />
                  <div class="error-message" v-show="missingMfaCode"
                    >Entering a code is required.</div
                  >
                </div>
              </div>
            </div>
          </div>

          <div >
            <div class="form-group">
              <div class="controls">
                <input
                  type="submit"
                  v-on:click.prevent="handleLogin"
                  v-bind:disabled="processingLogin"
                  class="btn btn-success login-button"
                  value="Login"
                />
              </div>
              <div v-show="errorMessage" class="error-message">
                {{ errorMessage }}
              </div>
              <div v-show="processingLogin">
                <div style="margin-top:10px">
                  <img src="../../public/login.gif" alt="Logging in ..." />
                </div>
              </div>
            </div>
          </div>

          <div v-show="showCredentialsForm">
            <div >
              <a href="#" v-on:click.prevent="handleForgotUsername"
                >Forgot Username?</a
              >&nbsp;&nbsp;
              <a href="#" v-on:click.prevent="handleForgotPassword"
                >Forgot Password?</a
              >
            </div>
            <br />
            <div >
              <a v-bind:href="benefitsAppBaseUrl + '/Account/Register'"
                >Register as a new user</a
              >
            </div>
          </div>

          <div v-show="requiresTotpCode">
            <div >
              <a href="#" v-on:click.prevent="showMissingDeviceModal"
                >Don't have your device?</a
              >
            </div>
          </div>
        </form>
      </div>
    </div>

    <info-modal
      v-bind:show-modal="showInfoModal"
      v-on:close="closeInfoModal"
      v-bind:show-footer="false"
    >
      <span slot="header">
        <span class="h3">Don't Have Your Device?</span>
      </span>
      <div slot="body">
        If you have lost your device, contact your agency's administrator to
        <i>temporarily disable</i> your 2FA authentication. If you have a new
        device, ask the administrator to <i>deactivate</i> your existing 2FA
        registration so that you can register your new device during your next
        login.
      </div>
    </info-modal>
  </div>
</template>

<script>
import utils from "./utils.js";
    import EnModal from '@front-end/en-components/src/lib-components/EnModal.vue';

export default {
  props: ["loginHint", "loginUrl", "returnUrl", "benefitsAppBaseUrl"],
  components: {
    "info-modal": EnModal,
  },
  data() {
    return {
      username: this.loginHint,
      password: "",
      mfaCode: "",
      mfaCodeType: "",
      errorMessage: "",
      processingLogin: false,
      showCredentialsForm: true,
      showMfaCodeForm: false,
      loginButtonHasBeenClicked: false,
      showInfoModal: false,
    };
  },
  computed: {
    missingUsername() {
      return this.loginButtonHasBeenClicked && !this.username;
    },
    missingPassword() {
      return this.loginButtonHasBeenClicked && !this.password;
    },
    missingMfaCode() {
      return this.loginButtonHasBeenClicked && !this.mfaCode;
    },
    requiresTotpCode() {
      return this.showMfaCodeForm && this.mfaCodeType == "totp";
    },
    requiresEmailCode() {
      return this.showMfaCodeForm && this.mfaCodeType == "email";
    },
  },
  methods: {
    handleForgotUsername() {
      this.$emit("forgot-username-requested");
    },
    handleForgotPassword() {
      this.$emit("forgot-password-requested", {
        suggestedUsername: this.username,
      });
    },
    handleLogin() {
      this.loginButtonHasBeenClicked = true;

      if (
        this.missingUsername ||
        this.missingPassword ||
        (this.showMfaCodeForm && this.missingMfaCode)
      ) {
        return;
      }

      this.errorMessage = "";
      this.processingLogin = true;

      utils
        .ajax("post", this.loginUrl, {
          username: this.username,
          password: this.password,
          mfaCode: this.mfaCode || null,
          returnUrl: this.returnUrl,
        })
        .then((response) => {
          if (response.data.errorMessage) {
            this.processingLogin = false;
            this.errorMessage = response.data.errorMessage;
          } else if (response.data.requiresMfaCode) {
            this.processingLogin = false;
            this.loginButtonHasBeenClicked = false;

            this.showCredentialsForm = false;
            this.mfaCodeType = response.data.mfaCodeType;
            this.showMfaCodeForm = true;
            this.$nextTick(() => this.$refs.mfaCodeInput.focus());
          } else if (response.data.requiresTotpSetup) {
            this.$emit("totp-setup-required", {
              username: this.username,
              password: this.password,
              qrCodeImage: response.data.totpSetupInfo.qrCodeImage,
              secretKey: response.data.totpSetupInfo.secretKey,
            });
          } else if (response.data.requiresDuoValidation) {
            this.$emit("duo-validation-required", {
              username: this.username,
              password: this.password,
              host: response.data.duoRequestInfo.host,
              signatureRequest: response.data.duoRequestInfo.signatureRequest,
            });
          } else if (response.data.redirectUrl) {
            window.location = response.data.redirectUrl;
          }
        })
        .catch((err) => {
          this.processingLogin = false;
          this.errorMessage =
            "An Unknown error has occurred. Please try again later";
          utils.logError(err);
        });
    },
    showMissingDeviceModal() {
      this.showInfoModal = true;
    },
    closeInfoModal() {
      this.showInfoModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.login-button {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
