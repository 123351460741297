<template>
  <div v-cloak>
    <div >
      <div >
        <form>
          <div v-show="showSetup">
            <div >
              <h4>Two Factor Authentication Registration</h4>
              <p>
                Your account is setup to use two-factor authentication, but
                before you can log in you must first perform this one-time
                registration process.
              </p>
              <p>
                First, if you have not done so already, you'll need to download
                an authentication app to your mobile device. An example of an
                authentication app is Google Authenticator, but please contact
                your company's administratior to see if there is a preferred app
                for your company.
              </p>
              <p>
                After completing this step, open the app and choose to add a new
                account. When prompted to do so, scan the below QR code to
                complete the process. Once complete, click Next to continue:
              </p>

              <div class="text-center">
                <img v-bind:src="setupInfo.qrCodeImage" class="qrImage" />
                <div>
                  <a href="#" v-on:click="showSecretKeyModal"
                    >Can't scan the QR Code?</a
                  >
                </div>
              </div>
            </div>

            <div >
              <div class="form-group">
                <div class="controls">
                  <a
                    v-on:click.prevent="handleNext"
                    class="btn btn-success login-button"
                    >Next</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-show="showConfirmSetup">
            <div >
              <h4>Confirm Registration</h4>
              <p>
                After your QR Code has been scanned, a unique six-digit code
                will be presented on your mobile device. Input this six-digit
                code below to complete the registration process. The code will
                expire every thirty seconds, so be sure to complete this process
                in a timely manner:
              </p>
            </div>

            <div >
              <div class="form-group">
                <label class="control-label" for="MfaCode"
                  >Code</label
                >
                <div class="controls">
                  <input
                    class="form-control"
                    id="MfaCode"
                    v-model="mfaCode"
                    ref="mfaCodeInput"
                  />
                  <span
                    class="text-danger"
                    v-show="missingMfaCode && !errorMessage"
                    >you forgot to put in a code</span
                  >
                  <span
                    class="text-danger"
                    v-show="errorMessage"
                    v-text="errorMessage"
                  ></span>
                </div>
              </div>
            </div>

            <div >
              <div class="form-group">
                <div class="controls">
                  <input
                    type="submit"
                    v-on:click.prevent="handleLogin"
                    v-bind:disabled="processingLogin"
                    class="btn btn-success login-button"
                    value="Login"
                  />
                </div>

                <div class="text-center">
                  <a href="#" v-on:click="handleGoBack">Go back</a>
                </div>

                <div v-show="processingLogin">
                  <div style="margin-top:10px">
                    <img src="../../public/login.gif" alt="Logging in ..." />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <secret-key-modal
      v-bind:show-modal="SecretKeyModalEnabled"
      v-on:close="closeSecretKeyModal"
      v-bind:show-footer="false"
    >
      <span slot="header">
        <span class="h3">Can't scan the QR Code?</span>
      </span>
      <div slot="body">
        If you're having trouble scanning this QR Code, most authentication apps
        allow you to manually enter the secret key. Locate this option within
        your app and then enter the following secret key:

        <h4 class="text-info text-center">{{ setupInfo.secretKey }}</h4>
      </div>
    </secret-key-modal>
  </div>
</template>

<script>
import utils from "./utils.js";
    import EnModal from '@front-end/en-components/src/lib-components/EnModal.vue';

export default {
  props: ["loginUrl", "returnUrl", "setupInfo"],
  components: {
    "secret-key-modal": EnModal
  },
  data() {
    return {
      mfaCode: "",
      errorMessage: "",
      processingLogin: false,
      buttonText: "Next",
      showSetup: true,
      showConfirmSetup: false,
      SecretKeyModalEnabled: false,
      loginButtonHasBeenClicked: false
    };
  },
  computed: {
    missingMfaCode() {
      return this.loginButtonHasBeenClicked && !this.mfaCode;
    }
  },
  methods: {
    handleNext() {
      this.showSetup = false;
      this.showConfirmSetup = true;
      this.$nextTick(() => this.$refs.mfaCodeInput.focus());
    },
    handleGoBack() {
      this.mfaCode = "";
      this.errorMessage = "";
      this.loginButtonHasBeenClicked = false;
      this.showSetup = true;
      this.showConfirmSetup = false;
    },
    showSecretKeyModal() {
      this.SecretKeyModalEnabled = true;
    },
    closeSecretKeyModal() {
      this.SecretKeyModalEnabled = false;
    },
    handleLogin() {
      this.loginButtonHasBeenClicked = true;

      if (this.missingMfaCode) {
        return;
      }

      this.errorMessage = "";
      this.processingLogin = true;

      utils
        .ajax("post", this.loginUrl, {
          username: this.setupInfo.username,
          password: this.setupInfo.password,
          mfaCode: this.mfaCode,
          returnUrl: this.returnUrl
        })
        .then(response => {
          if (response.data.errorMessage) {
            this.processingLogin = false;
            this.errorMessage = response.data.errorMessage;
          } else if (response.data.redirectUrl) {
            window.location = response.data.redirectUrl;
          }
        })
        .catch(err => {
          this.processingLogin = false;
          this.errorMessage =
            "An Unknown error has occurred. Please try again later";
          utils.logError(err);
        });
    }
  }
};
</script>

<style scoped lang="scss">
h4 {
  font-weight: bold;
}

.login-button {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.qrImage {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
