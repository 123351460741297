<template>
  <div>
    <standard-header v-bind:custom-logo-url="customLogoUrl" />

    <login-form
      v-if="showLogin"
      v-bind:login-url="loginUrl"
      v-bind:login-hint="loginHint"
      v-bind:return-url="returnUrl"
      v-bind:benefits-app-base-url="benefitsAppBaseUrl"
      v-on:totp-setup-required="handleTotpSetupRequired"
      v-on:duo-validation-required="handleDuoValidationRequired"
      v-on:forgot-username-requested="handleForgotUsername"
      v-on:forgot-password-requested="handleForgotPassword"
    />

    <totp-setup
      v-if="showTotpSetup"
      v-bind:login-url="loginUrl"
      v-bind:return-url="returnUrl"
      v-bind:setup-info="totpSetupInfo"
    />

    <duo-validation
      v-if="showDuoValidation"
      v-bind:login-url="loginUrl"
      v-bind:return-url="returnUrl"
      v-bind:duo-info="duoValidationInfo"
    />

    <forgot-username
      v-if="showForgotUsername"
      v-bind:forgot-username-url="forgotUsernameUrl"
      v-bind:branding-subdomain="brandingSubdomain"
      v-bind:benefits-app-base-url="benefitsAppBaseUrl"
    />

    <forgot-password
      v-if="showForgotPassword"
      v-bind:forgot-password-url="forgotPasswordUrl"
      v-bind:branding-subdomain="brandingSubdomain"
      v-bind:forgot-password-info="forgotPasswordInfo"
      v-bind:benefits-app-base-url="benefitsAppBaseUrl"
      v-on:forgot-username-requested="handleForgotUsername"
    />

    <standard-footer />
  </div>
</template>

<script>
import LoginHeader from "./components/LoginHeader.vue";
import LoginForm from "./components/LoginForm.vue";
import TotpSetup from "./components/TotpSetup.vue";
import DuoValidation from "./components/DuoValidation.vue";
import ForgotUsername from "./components/ForgotUsername.vue";
import ForgotPassword from "./components/ForgotPassword.vue";
import LoginFooter from "./components/LoginFooter.vue";

export default {
  name: 'LoginApp',
  data() {
    return {
      showLogin: true,
      showTotpSetup: false,
      showDuoValidation: false,
      showForgotUsername: false,
      showForgotPassword: false,
      totpSetupInfo: null,
      duoValidationInfo: null,
      forgotPasswordInfo: null,
    };
  },
  props: [
    "returnUrl",
    "loginHint",
    "loginUrl",
    "forgotUsernameUrl",
    "forgotPasswordUrl",
    "benefitsAppBaseUrl",
    "customLogoUrl",
    "brandingSubdomain",
  ],
  components: {
    "standard-header": LoginHeader,
    "login-form": LoginForm,
    "totp-setup": TotpSetup,
    "duo-validation": DuoValidation,
    "forgot-username": ForgotUsername,
    "forgot-password": ForgotPassword,
    "standard-footer": LoginFooter,
  },
  methods: {
    handleTotpSetupRequired(e) {
      this.hideAllSections();

      this.totpSetupInfo = e;
      this.showTotpSetup = true;
    },
    handleDuoValidationRequired(e) {
      this.hideAllSections();

      this.duoValidationInfo = e;
      this.showDuoValidation = true;
    },
    handleForgotUsername() {
      this.hideAllSections();
      this.showForgotUsername = true;
    },
    handleForgotPassword(e) {
      this.hideAllSections();

      this.forgotPasswordInfo = e;
      this.showForgotPassword = true;
    },
    hideAllSections() {
      this.showLogin = false;
      this.showTotpSetup = false;
      this.showForgotUsername = false;
      this.showForgotPassword = false;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
