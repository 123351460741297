<template>
  <div v-cloak>
    <div >
      <div >
        <div v-show="showForm">
          <h4>Retrieve Forgotten Username</h4>
          <p>
            Enter the email address associated with your account below.
          </p>
          <p>
            An email including your username will be sent within 10 minutes.
          </p>

          <div class="form-group">
            <label class="control-label" for="emailAddress"
              >Email Address</label
            >
            <div class="controls">
              <input
                class="form-control"
                id="emailAddress"
                v-model.trim="emailAddress"
                v-on:keyup.enter="handleSend"
                ref="emailAddress"
              />
              <span class="text-danger" v-show="invalidEmailAddress"
                >Please enter a valid email address</span
              >
            </div>
          </div>

          <div class="form-group">
            <div class="controls">
              <a
                v-on:click.prevent="handleSend"
                class="btn btn-success send-button"
                >Send Email</a
              >
            </div>
          </div>

          <div v-show="errorMessage" class="text-danger">
            {{ errorMessage }}
          </div>

          <div v-show="processingSend">
            <div style="margin-top:10px">
              <img src="../../public/login.gif" alt="Processing..." />
            </div>
          </div>

          <table class="table table-bordered">
            <tbody>
              <tr>
                <td class="important-note">
                  If you do not have an email address or cannot access your
                  email account, click
                  <a
                    class="bold-text"
                    v-bind:href="
                      benefitsAppBaseUrl +
                        '/Account/Reset/RetrieveForgottenUsername'
                    "
                  >
                    here
                  </a>
                  to retrieve your username using an alternate method.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-show="showConfirmation">
          <div>
            <h4>Check Your Email</h4>
            <p>
              Your username is included in the message for future reference.
            </p>
            <br />
            <br />
            <div class="important-note">
              <p>
                Didn't receive an email?
              </p>
              <p>
                Return to the
                <a class="bold-text" href="#" v-on:click.prevent="handleGoBack">
                  Forgot Username
                </a>
                page and ensure your email address was entered correctly.
              </p>
              <p>
                If the problem persists or there is not an email address
                associated with your account, click
                <a
                  class="bold-text"
                  v-bind:href="
                    benefitsAppBaseUrl +
                      '/Account/Reset/RetrieveForgottenUsername'
                  "
                >
                  here
                </a>
                to retrieve your username using an alternate method.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "./utils.js";

export default {
  props: ["forgotUsernameUrl", "brandingSubdomain", "benefitsAppBaseUrl"],
  data() {
    return {
      showForm: true,
      showConfirmation: false,
      emailAddress: "",
      errorMessage: "",
      processingSend: false,
      sendButtonHasBeenClicked: false,
    };
  },
  mounted() {
    this.$refs.emailAddress.focus();
  },
  computed: {
    invalidEmailAddress() {
      // eslint-disable-next-line
      const validEmail = /^((([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/;

      return (
        this.sendButtonHasBeenClicked && !validEmail.test(this.emailAddress)
      );
    },
  },
  methods: {
    handleSend() {
      this.sendButtonHasBeenClicked = true;

      if (this.invalidEmailAddress) {
        return;
      }

      this.errorMessage = "";
      this.processingSend = true;

      utils
        .ajax("post", this.forgotUsernameUrl, {
          emailAddress: this.emailAddress,
          brandingSubdomain: this.brandingSubdomain,
        })
        .then((response) => {
          this.processingSend = false;

          if (response.data.errorMessage) {
            this.errorMessage = response.data.errorMessage;
          } else {
            this.showForm = false;
            this.showConfirmation = true;
          }
        })
        .catch((err) => {
          this.processingSend = false;
          this.errorMessage =
            "An Unknown error has occurred. Please try again later";
          utils.logError(err);
        });
    },
    handleGoBack() {
      this.showConfirmation = false;
      this.showForm = true;
      this.$nextTick(() => this.$refs.emailAddress.focus());
    },
  },
};
</script>

<style scoped lang="scss">
h4 {
  font-weight: bold;
}

.important-note {
  font-style: italic;
}

.bold-text {
  font-weight: bold;
}

.send-button {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
