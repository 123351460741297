import { render, staticRenderFns } from "./TotpSetup.vue?vue&type=template&id=9f181c3c&scoped=true&"
import script from "./TotpSetup.vue?vue&type=script&lang=js&"
export * from "./TotpSetup.vue?vue&type=script&lang=js&"
import style0 from "./TotpSetup.vue?vue&type=style&index=0&id=9f181c3c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f181c3c",
  null
  
)

export default component.exports