import Vue from "vue";
import LoginApp from "./LoginApp.vue";

Vue.config.productionTip = false;

// load server data to pass in as props
const props = {
  returnUrl: document.querySelector("#ReturnUrl").value,
  loginHint: document.querySelector("#LoginHint").value,
  loginUrl: document.querySelector("#LoginUrl").value,
  forgotUsernameUrl: document.querySelector("#ForgotUsernameUrl").value,
  forgotPasswordUrl: document.querySelector("#ForgotPasswordUrl").value,
  benefitsAppBaseUrl: document.querySelector("#BenefitsAppBaseUrl").value,
  customLogoUrl: document.querySelector("#CustomLogoUrl").value,
  brandingSubdomain: document.querySelector("#BrandingSubdomain").value,
};

// kick off Vue app
new Vue({
  render: (h) => h(LoginApp, { props }),
}).$mount("#app");
